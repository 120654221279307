import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

let RootDirectory = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  RootDirectory
);

